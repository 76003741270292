import { AccordionLayoutProps } from './accordionCommonTypes';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles';

export type AccordionLayout =
    | 'LAYOUT_1'
    | 'LAYOUT_2'
    | 'LAYOUT_3'
    | 'LAYOUT_4'
    | 'LAYOUT_5'
   

export const accordionlayoutTypeToDesignData: Record<
    AccordionLayout,
    AccordionLayoutProps
> = {
    LAYOUT_1: {
        arrowDirection: 'right',
        hasTitleIcons: false,
        styles: {
            container:{
                [RuntimeMediaQuery.COMMON]: {
                    gap: 24,
                },
            },
            itemTitleWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    background: '#f2f2f2',
                },
            },
            itemArrowWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    borderRadius: '50%',
                    width: 32,
                    padding: 4,
                    backgroundColor: '#000',
                    color: '#e3e3e3',
                },
            },
           
        },
    },
    LAYOUT_2: {
        arrowDirection: 'right',
        arrowType: 'plus',
        hasTitleIcons: false,
        styles: {
            container:{
                [RuntimeMediaQuery.COMMON]: {
                    gap: 0,
                },
            },
            itemTitleWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    borderBottom: '1px solid #e3e3e3'
                },
            },
            itemArrowWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    padding: 8,
                    width: 30
                },
            },
        },
    },
    LAYOUT_3: {
        arrowDirection: 'right',
        arrowType: 'arrow',
        hasTitleIcons: false,
        styles: {
            container: {
                [RuntimeMediaQuery.COMMON]: {
                    border: 'solid 1px #e1e1e1',
                    borderBottom: 'none'
                },
            },
            itemTitleWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    padding: '18px 20px',
                    borderBottom: 'solid 1px #e1e1e1',
                },
            },
            itemDesc: {
                [RuntimeMediaQuery.COMMON]: {
                    borderBottom: 'solid 1px #e1e1e1',
                    background: '#f2f2f2'
                },
               
            }
        },
    },
    LAYOUT_4: {
        arrowDirection: 'right',
        hasTitleIcons: true,
        styles: {
            container:{
                [RuntimeMediaQuery.COMMON]: {
                    gap: 10,
                },
            },
            itemTitleWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    background: '#f2f2f2',
                },
            },
            itemArrowWrapper: {
                [RuntimeMediaQuery.COMMON]: {
                    padding: 8,
                    width: 40
                },
            },
        },
    },
    LAYOUT_5: {
        arrowDirection: 'left',
        arrowType: 'leftArrow',
        hasTitleIcons: false,
        styles: {
        },
    },
   

   
};
