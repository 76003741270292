import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { Div } from 'client/widget-components/basicComponents';
import LinkFunctionalityWrapper from 'client/widget-components/basicComponents/LinkFunctionalityWrapper/LinkFunctionalityWrapper';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';

import React, { FC, ReactNode } from 'react';

function hasAnyLink(
    obj: Props['linkFunctionalityDomAttributes']
): obj is Record<string, string | null> {
    if (!obj) {
        return false;
    }
    return Object.values(obj).some((value) => value != null);
}

interface Props {
    styles?: ResponsiveStylesProp;
    linkFunctionalityDomAttributes?: Record<string, string | null> | false;
    children: ReactNode;
    'data-auto': string;
}

const SlideContainer: FC<Props> = (props) => {
    const styles = [containerDefaultStyle, props.styles];

    if (hasAnyLink(props.linkFunctionalityDomAttributes)) {
        return (
            <LinkFunctionalityWrapper
                {...props}
                linkFunctionalityDomAttributes={
                    props.linkFunctionalityDomAttributes
                }
                styles={styles}
            />
        );
    }
    return <Div {...props} styles={styles} />;
};

const containerDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'block',
        overflow: 'hidden',
    },
};

export default SlideContainer;
