import React, { FC } from 'react';
import { Div, Span } from '../../basicComponents';
import { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
} from '../../responsive-styles/responsiveStylesService';
import Text from '../../basicComponents/Text';
import TooltipArrow from '../icons/TooltipArrow';

export interface TooltipProps {
    children: React.ReactNode;
    text: string;
}

const Tooltip: FC<TooltipProps> = ({ children, text }) => {
    return (
        <Span
            data-auto={'tooltip-container'}
            styles={[
                tooltipContainerStyle,
                nestResponsiveStyles(mergeResponsiveStyles(tooltipHoverStyle), {
                    pseudoSelector: 'hover',
                    innerSelector: '[data-auto="tooltip-content"]',
                }),
            ]}
        >
            {children}
            <Div styles={tooltipContentStyle} data-auto={'tooltip-content'}>
                <TooltipArrow />
                <Div styles={tooltipBoxStyle} data-auto={'tooltip-content-box'}>
                    <Text
                        styles={tooltipContentTextStyle}
                        grabId={'tooltip-content-text'}
                    >
                        {text}
                    </Text>
                </Div>
            </Div>
        </Span>
    );
};

const tooltipContainerStyle: ResponsiveStylesStructured = {
    common: {
        height: '100%',
    },
};

const tooltipContentStyle: ResponsiveStylesStructured = {
    common: {
        visibility: 'hidden',
        marginLeft: '25%',
        marginRight: '25%',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: '100',
    },
};

const tooltipContentTextStyle: ResponsiveStylesStructured = {
    common: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '550',
        lineHeight: '18px',
        color: '#fff',
        flex: '1 0 0',
    },
};
const tooltipBoxStyle: ResponsiveStylesStructured = {
    common: {
        display: 'flex',
        maxWidth: '200px',
        alignItems: 'flex-start',
        gap: '4px',
        padding: '10px 16px',
        borderRadius: '4px',
        background: '#313131',
    },
};

const tooltipHoverStyle: ResponsiveStylesStructured = {
    common: {
        visibility: 'visible',
    },
};

export default Tooltip;
