import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ecomObserver } from '@shared-services/ecom-service';
import { localizationService } from '@shared-services/localization-service';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import { RadioButton } from 'client/widget-components';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import { Div } from 'client/widget-components/basicComponents';
import { RuntimeProductPriceOptionsProps } from './productPriceOptionsTypes';
import { ProductPriceOptionsItem } from './ProductPriceOptionsItem';

const RuntimeProductPriceOptions: FC<RuntimeProductPriceOptionsProps> = ({
    productState,
    _styles: styles,
}) => {
    if (!productState || !productState.paymentPlanOptions.length) {
        return null;
    }

    return (
        <Div data-auto='price-options-widget'>
            <Text
                styles={[titleDefaultStyles, styles?.title]}
                tag={TextDomTag.h4}
                grabId='price-options-title'
            >
                {localizationService.str(
                    'ui.runtimessr.productPriceOptions.title'
                )}
            </Text>

            <Div styles={containerDefaultStyles}>
                {productState.paymentPlanOptions.map((option) => (
                    <RadioButton
                        key={option.id}
                        data-grab='price-option'
                        data-auto='price-option'
                        value={option.id}
                        selectedValue={
                            productState.selectedPaymentPlan?.id || ''
                        }
                        label={
                            <ProductPriceOptionsItem
                                option={option}
                                styles={styles}
                            />
                        }
                        labelTag={TextDomTag.div}
                        onChange={() => {
                            productState.selectPaymentPlanOption(option);
                        }}
                        labelWrapperStyles={[
                            optionDefaultStyles,
                            styles?.option,
                        ]}
                        labelStyles={labelDefaultStyles}
                        inputStyles={styles?.input}
                        inputIconStyles={styles?.inputIcon}
                    />
                ))}
            </Div>
        </Div>
    );
};

const titleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        paddingBottom: 8,
        margin: 0,
    },
};

const containerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexFlow: 'column',
        gap: 16,
    },
};

const optionDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        border: '1px solid #CED6D9',
        borderRadius: 3,
        padding: 16,
        minHeight: 67,
        gap: 16,
    },
};

const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'block',
        textTransform: 'none',
    },
};

const RuntimeProductPriceOptionsWidget = withErrorBoundary({
    Comp: ecomObserver(RuntimeProductPriceOptions),
    componentName: 'RuntimeProductPriceOptions',
    logProps: true,
});

export default RuntimeProductPriceOptionsWidget;
