import React, { InputHTMLAttributes, memo } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

import { Input } from 'client/widget-components/basicComponents/Input/Input';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesProp,
} from 'client/widget-components/responsive-styles/responsiveStylesService';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    thumbStyles?: ResponsiveStylesProp;
    trackStyles?: ResponsiveStylesProp;
    isActive: boolean;
}

function RangeInput(props: Props) {
    const thumbStyles = mergeResponsiveStyles([
        thumbDefaultStyles,
        props.trackStyles,
        props.thumbStyles,
        props.isActive && activeThumbStyles,
        thumbOverridesMediaQueryStyles,
    ]);
    const inputStyles = [
        defaultInputStyles,
        props.isActive && activeThumbStyles,
        nestResponsiveStyles(
            {
                [RuntimeMediaQuery.COMMON]: {
                    height: '100%',
                },
            },
            {
                innerSelector: '&::-webkit-slider-runnable-track',
            }
        ),
        nestResponsiveStyles(thumbStyles, {
            innerSelector: '&&::-webkit-slider-thumb',
        }),
        nestResponsiveStyles(thumbStyles, {
            innerSelector: '&::-moz-range-thumb',
        }),
    ];
    return <Input type='range' styles={inputStyles} {...props} />;
}

const defaultInputStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
        height: '200%',
        width: '100%',
        position: 'absolute',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        pointerEvents: 'none',
        cursor: 'pointer',
        minHeight: '8px',
    },
    [RuntimeMediaQuery.MOBILE]: {
        minHeight: '20px',
    },
};

const thumbDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        WebkitAppearance: 'none',
        backgroundColor: '#000',
        pointerEvents: 'all',
        borderRadius: '50%',
        aspectRatio: '1/1',
        width: 'unset',
        border: '0',
        boxShadow: '0 0 1px 1px rgba(0,0,0,0.4)',
        zIndex: 2,
    },
};

const activeThumbStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        zIndex: 3,
        pointerEvents: 'all',
    },
};

const thumbOverridesMediaQueryStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '100%',
    },
    [RuntimeMediaQuery.TABLET]: {
        height: '100%',
    },
    [RuntimeMediaQuery.DESKTOP]: {
        height: '100%',
    },
    [RuntimeMediaQuery.MOBILE]: {
        height: '100%',
    },
};

export default memo(RangeInput);
