import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { Div } from 'client/widget-components/basicComponents';
import { SearchBtn } from './SearchBtn/SearchBtn';

interface Props {
    styles?: {
        iconBtn?: ResponsiveStylesProp;
    };
}

export const HiddenSearchComponentsForEditorComputedStyles: FC<Props> = ({
    styles,
}) => {
    return (
        <Div styles={hiddenDivStyles}>
            <SearchBtn styles={styles} dataAuto='' openSearchPopup={() => {}} />
        </Div>
    );
};

const hiddenDivStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
    },
};
