import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

import { CSSProperties } from 'styled-components';
import { mergeDeepPure } from 'client/utils';
import { context } from '@shared-services/context-service';
import normalizeStylesToCamelCase from './normalizeStylesToCamelCase';

type ModeledStruct<CSS> = {
    [key in RuntimeMediaQuery]?: CSS;
};

interface CSSNested {
    [selector: string]: CSSProperties;
}

export type ResponsiveStylesNested = ModeledStruct<CSSNested>;
export type ResponsiveStylesWide = ModeledStruct<
    CSSProperties | CSSNested | (CSSProperties & CSSNested)
>;

export type ResponsiveStylesProp = DeepArray<
    ResponsiveStylesWide | undefined | false
>;

function mergeStyleOntoBase(
    baseStyles: ResponsiveStylesWide,
    newStyles?: ResponsiveStylesWide
) {
    if (!newStyles) {
        return baseStyles;
    }
    const _newStyles = normalizeStylesToCamelCase(newStyles);
    return mergeDeepPure(baseStyles, _newStyles);
}

export function mergeResponsiveStyles(
    styles: ResponsiveStylesProp
): ResponsiveStylesWide {
    const arrayedStyles: ResponsiveStylesProp[] = Array.isArray(styles)
        ? styles
        : [styles];
    const flatStyles = (arrayedStyles.flat as any)(Infinity) as Array<
        ResponsiveStylesWide | undefined
    >;

    return flatStyles.reduce(mergeStyleOntoBase, {} as ResponsiveStylesWide);
}

export function nestResponsiveStyles(
    styleModel: ResponsiveStylesStructured = {},
    nestUnder: {
        pseudoSelector?: 'hover' | 'focus' | 'focus-visible';
        innerSelector?: string;
    }
): ResponsiveStylesNested {
    const { pseudoSelector, innerSelector } = nestUnder;

    const newStylesObject: ResponsiveStylesNested = {};
    (
        Object.entries(styleModel) as Array<[RuntimeMediaQuery, CSSProperties]>
    ).forEach(([mQ, cssProps]) => {
        /**
         * TODO @todo clean or extract function for keys
         */
        let cssObj: CSSNested;
        if (pseudoSelector) {
            cssObj = {
                [`&:${pseudoSelector} ${innerSelector ? innerSelector : ''}`]:
                    cssProps,
            };
            if (context.isEditor) {
                cssObj[
                    `&.${pseudoSelector} ${innerSelector ? innerSelector : ''}`
                ] = cssProps;
            }
        } else if (innerSelector) {
            cssObj = {
                [innerSelector]: cssProps,
            };
        } else {
            cssObj = {};
        }
        newStylesObject[mQ] = cssObj;
    });
    return newStylesObject;
}

type DeepArray<T> = T | Array<T | DeepArray<T>>;
