import React from 'react';

import { BulletsPaginationProps } from '../PaginationProps';
import BulletsBase from '../BulletsBase';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

const Bullets: React.FC<BulletsPaginationProps> = ({
    cursor,
    totalPages,
    styles,
    ...otherProps
}) => {
    return (
        <BulletsBase
            bulletsList={new Array(totalPages).fill(null).map((_, index) => {
                const isActive = index === cursor ? ' active' : '';
                return {
                    styles: [
                        cursor === index
                            ? styles?.buttonBulletActive
                            : undefined,
                    ],
                    domAttributes: {
                        'data-auto': `pagination-button-bullet ${index}${isActive}`,
                        'data-grab': `pagination-button-bullet${isActive}`,
                    },
                };
            })}
            styles={{
                container: [containerDefaultStyles, styles?.container],
                buttonBullet: [buttonDefaultStyle, styles?.buttonBullet],
            }}
            {...otherProps}
        />
    );
};

const buttonDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        borderRadius: '50%',
        width: 8,
        height: 8,
        boxShadow:
            '0px 0px 2px rgba(24, 39, 75, 0.3), 0px 0px 3px rgba(24, 39, 75, 0.04)',
    },
};

const containerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        gap: 8,
    },
};

export default Bullets;
