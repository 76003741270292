import { DeprecatedWidgetStyles } from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import { logger } from '@shared-services/log-service';
import { DeprecatedRuntimeBreakpoints } from '@duda-co/runtime-breakpoints';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles';
import { NewBPStyles } from './types';

const bpMigrationKeyMap = {
    [DeprecatedRuntimeBreakpoints.DEFAULT]: RuntimeMediaQuery.COMMON,
    [DeprecatedRuntimeBreakpoints.DESKTOP]: RuntimeMediaQuery.DESKTOP,
    [DeprecatedRuntimeBreakpoints.MOBILE_PORTRAIT]: RuntimeMediaQuery.MOBILE,
    [DeprecatedRuntimeBreakpoints.TABLET]: RuntimeMediaQuery.TABLET,
    [DeprecatedRuntimeBreakpoints.LARGE_DESKTOP]: RuntimeMediaQuery.DESKTOP,
};

export function migrateOldBPStyles(
    deprecatedStyles: DeprecatedWidgetStyles,
    migrationStyleKeys: { [key: string]: string } = {}
): NewBPStyles {
    try {
        const styles: DeprecatedWidgetStyles = deprecatedStyles.rules || {};
        return Object.entries(styles).reduce(
            (styleResult: NewBPStyles, [stylePropKey, stylePropValue]) => {
                Object.entries(bpMigrationKeyMap).forEach(
                    ([oldKey, newKey]) => {
                        const styleKey =
                            migrationStyleKeys[stylePropKey] || stylePropKey;
                        const propValue =
                            stylePropValue[oldKey] || stylePropValue[newKey];
                        if (propValue) {
                            styleResult[styleKey] = {
                                ...(styleResult[styleKey] || {}),
                                [newKey]: propValue,
                            };
                        }
                    }
                );
                return styleResult;
            },
            {} as NewBPStyles
        );
    } catch (e) {
        logger.error({
            message: `Failed to migrate old BP styles structure`,
            errorMessage: e?.toString(),
            tags: [
                'widget migration',
                'style migration',
                'old breakpoints to new',
            ],
        });
        throw e;
    }
}
