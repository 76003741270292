import React, { FC, MouseEvent } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { StyledButton } from 'client/widget-components/basicComponents/StyledButton';
import { SearchInputIcon } from './SearchInputIcon';

interface SearchBtnProps {
    styles?: {
        iconBtn?: ResponsiveStylesProp;
    };
    dataAuto?: string;
    openSearchPopup: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const SearchBtn: FC<SearchBtnProps> = ({
    styles,
    dataAuto = 'search-icon',
    openSearchPopup,
}) => {
    return (
        <StyledButton
            type='button'
            data-auto={dataAuto}
            styles={[iconBtnDefaultStyles, styles?.iconBtn]}
            onClick={openSearchPopup}
            aria-label='search'
            data-grab='collection-search-widget-icon-btn'
        >
            <SearchInputIcon />
        </StyledButton>
    );
};

const iconBtnDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'inline-block',
        color: 'inherit',
        width: 26,
    },
};
