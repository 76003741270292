import React, { useCallback, useEffect, useState } from 'react';
import { RangeSliderProps, RangeValues } from './types';
import { Div } from 'client/widget-components/basicComponents';
import { debounce } from 'client/utils';
import RangeSliderLabels from './components/RangeSliderLabels';
import RangeSliderInputs from './components/RangeSliderInputs';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

const RangeSlider = (props: RangeSliderProps) => {
    const { currentValues } = props;
    const [rangeValues, setRangeValues] = useState<RangeValues>({
        start: currentValues?.start || props.min,
        end: currentValues?.end || props.max,
    });
    const onChange = useCallback(debounce(props.onChange, 300), []);

    useEffect(() => {
        if (
            rangeValues.start !== currentValues?.start ||
            rangeValues.end !== currentValues.end
        ) {
            setRangeValues({
                start: currentValues?.start || props.min,
                end: currentValues?.end || props.max,
            });
        }
    }, [currentValues]);

    return (
        <Div data-auto='range-slider' styles={wrapperDefaultStyles}>
            <RangeSliderLabels
                labelsWrapperStyles={props.labelsWrapperStyles}
                labelStyles={props.labelStyles}
                sign={props.sign}
                rangeValues={rangeValues}
            />
            <RangeSliderInputs
                onChange={(values: RangeValues) => {
                    setRangeValues(values);
                    onChange(values);
                }}
                trackStyles={props.trackStyles}
                thumbStyles={props.thumbStyles}
                min={props.min}
                max={props.max}
                rangeValues={rangeValues}
                hideRangeTrack={props.hideRangeTrack}
                trackDataGrab={props.trackDataGrab}
                minRange={props.minRange}
            />
        </Div>
    );
};

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        padding: '2px',
    },
};
export default RangeSlider;
export { type RangeSliderProps };
