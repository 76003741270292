import {
    ButtonContent,
    ButtonStyles,
    LinkFunctionalityDomAttributes,
} from 'client/widget-components/CommonModelTypes';
import { FC } from 'react';
import React from 'react';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import {
    mergeResponsiveStyles,
    nestResponsiveStyles,
    ResponsiveStylesWide,
} from 'client/widget-components/responsive-styles/responsiveStylesService';
import { ResponsiveStylesStructured } from '@duda-co/responsive-styles';
import LinkFunctionalityWrapper from 'client/widget-components/basicComponents/LinkFunctionalityWrapper/LinkFunctionalityWrapper';
import { getResponsiveComponent } from 'client/widget-components/basicComponents';
import { context } from '@shared-services/context-service';

interface Props {
    buttonContent: ButtonContent;
    styles: ButtonStyles;
    linkFunctionalityDomAttributes?: LinkFunctionalityDomAttributes;
    iconClass?: string | false;
    /**
     * nomralize reverts some css properties that come from global selectors and are not part of global button design. Legacy global button adds a lot of css values, and this normalization makes it a bit easier to work with the component
     */
    normalizeStyles?: boolean;
    showHoverEffect?: boolean;
    rootDataAttributes?:
        | Record<string, boolean>
        | Record<'onClick', () => void>;
    dataGrab?: string;
    isDisabled?: boolean;
    className?: string;
}

const Span = getResponsiveComponent('span', true);

const Button: FC<Props> = ({
    buttonContent,
    styles,
    linkFunctionalityDomAttributes,
    normalizeStyles = true,
    iconClass,
    showHoverEffect,
    rootDataAttributes,
    dataGrab,
    isDisabled = false,
    className,
}) => {
    const { text } = buttonContent;
    const normalizeObject = normalizeStyles ? normalizeDefaults : {};
    return (
        <LinkFunctionalityWrapper
            styles={[
                normalizeObject.root,
                styles.root,
                isDisabled
                    ? styles.rootDisabled
                    : [
                          nestResponsiveStyles(
                              mergeResponsiveStyles(styles.rootHover),
                              {
                                  pseudoSelector: 'hover',
                              }
                          ),
                          nestResponsiveStyles(
                              mergeResponsiveStyles(styles.rootHoverText),
                              {
                                  pseudoSelector: 'hover',
                                  innerSelector: Text.selector,
                              }
                          ),
                          nestResponsiveStyles(
                              mergeResponsiveStyles(styles.rootHoverIcon),
                              {
                                  pseudoSelector: 'hover',
                                  innerSelector: '[data-grab="button-icon"]',
                              }
                          ),
                      ],
            ]}
            linkFunctionalityDomAttributes={linkFunctionalityDomAttributes}
            classNames={[
                'dmWidget',
                styles.buttonTypeCSSClass ?? '',
                showHoverEffect ? 'hover' : '',
                isDisabled ? 'data-disabled' : '',
                className || '',
            ]}
            grabId={dataGrab || 'button-root'}
            data-disabled={isDisabled}
            {...rootDataAttributes}
        >
            {iconClass && (
                <Span
                    styles={[styles.icon, isDisabled && styles.iconDisabled]}
                    data-grab='button-icon'
                    className={`icon ${iconClass}`}
                />
            )}
            <Text
                styles={[
                    normalizeObject.text,
                    textStyleDefault,
                    styles.text,
                    isDisabled && styles.disabledText,
                ]}
                grabId='button-text'
                domAttrs={{
                    className: 'text',
                }}
                tag={TextDomTag.span}
            >
                {text}
            </Text>
        </LinkFunctionalityWrapper>
    );
};

const normalizeTextDefault: ResponsiveStylesStructured = {
    common: {
        maxWidth: 'revert',
        whiteSpace: 'revert',
        display: 'inline-block',
    },
};

const normalizedRoot: ResponsiveStylesWide = {
    common: {
        '&:after': {
            display: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const normalizeDefaults: ButtonStyles = {
    text: normalizeTextDefault,
    root: normalizedRoot,
};

const textStyleDefault: ResponsiveStylesStructured = {
    common: {
        flexGrow: '1',
    },
};

const ButtonForEditorComputation: FC<Props> = (props) => {
    const hiddenButtonStyles = {
        ...props.styles,
        root: [
            ...([props.styles.root] || []),
            {
                common: {
                    display: 'none',
                },
            },
        ],
    };
    return (
        <>
            <Button {...props} />
            <Button
                {...props}
                isDisabled={!props.isDisabled}
                styles={hiddenButtonStyles}
            />
        </>
    );
};

export default function (props: Props) {
    return context.isEditor ? (
        <ButtonForEditorComputation {...props} />
    ) : (
        <Button {...props} />
    );
}
