import RadioButton, { RadioButtonProps } from '../RadioButton';
import { Div } from 'client/widget-components/basicComponents';
import Text, { TextDomTag } from '../../basicComponents/Text';
import React from 'react';
import { ResponsiveStylesProp } from '../../responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';

interface RadioButtonsGroupStyles {
    wrapper?: ResponsiveStylesStructured;
    title?: ResponsiveStylesStructured;
    fields?: FieldsStyles;
}

interface FieldsStyles {
    outerCircle?: ResponsiveStylesProp;
    innerCircle?: ResponsiveStylesProp;
    label?: ResponsiveStylesProp;
    wrapper?: ResponsiveStylesProp;
}

export interface RadioButtonOption {
    label?: string;
    value: string | number;
    disabled?: boolean;
}

export interface RadioButtonsGroupProps
    extends Omit<
        RadioButtonProps,
        'label' | 'value' | 'onChange' | 'selectedValue' | 'disabled'
    > {
    title?: string;
    className?: string;
    options: RadioButtonOption[];
    selectedValue?: RadioButtonOption;
    onChange: (value: RadioButtonOption) => void;
    styles?: RadioButtonsGroupStyles;
}

export default ({
    title,
    options,
    selectedValue,
    onChange,
    styles,
    ...radioButtonProps
}: RadioButtonsGroupProps) => {
    return (
        <Div data-auto='radio-buttons-group'>
            <Text
                tag={TextDomTag.h4}
                styles={[titleDefaultStyles, styles?.title]}
                data-grab='radiogroup-title'
            >
                {title}
            </Text>

            <Div
                styles={[wrapperDefaultStyles, styles?.fields?.wrapper]}
                data-grab='radiogroup-fields'
            >
                {options.map((option) => (
                    <RadioButton
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        disabled={option.disabled}
                        selectedValue={selectedValue?.value}
                        onChange={() => onChange(option)}
                        labelWrapperStyles={defaultLabelWrapperStyles}
                        inputStyles={styles?.fields?.outerCircle}
                        inputIconStyles={styles?.fields?.innerCircle}
                        labelStyles={styles?.fields?.label}
                        dataGrabs={{
                            labelDataGrab: 'radio-label',
                        }}
                        {...radioButtonProps}
                    />
                ))}
            </Div>
        </Div>
    );
};

const wrapperDefaultStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        flexWrap: 'wrap',
    },
};

const defaultLabelWrapperStyles: ResponsiveStylesProp = {
    [RuntimeMediaQuery.COMMON]: {
        color: 'inherit',
    },
};

const titleDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textAlign: 'start',
        marginBlockEnd: '6px',
        lineHeight: '100%',
        marginTop: '0',
        fontSize: '16px',
        direction: 'inherit',
    },
};
