import { Div } from 'client/widget-components/basicComponents';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';
import React from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { LabelsProps, RangeValues } from '../types';

interface Props extends LabelsProps {
    rangeValues: RangeValues;
}
function RangeSliderLabels(props: Props) {
    return (
        <Div styles={[valuesWrapperDefaultStyles, props.labelsWrapperStyles]}>
            <Text
                styles={[labelDefaultStyles, props.labelStyles]}
                data-auto='min-input-label'
                tag={TextDomTag.paragraph}
            >
                <span>{props.rangeValues.start}</span>
                {props.sign && <span>{props.sign}</span>}
            </Text>
            <Text
                styles={[labelDefaultStyles, props.labelStyles]}
                data-auto='max-input-label'
                tag={TextDomTag.paragraph}
            >
                <span>{props.rangeValues.end}</span>
                {props.sign && <span>{props.sign}</span>}
            </Text>
        </Div>
    );
}

const labelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        margin: 0,
    },
};

const valuesWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBlockEnd: '12px',
    },
};
export default RangeSliderLabels;
