import React from 'react';
import { Accordion } from 'client/widget-components';
import { useFilterSortContext } from './utils/filterSortContext';
import { Span } from 'client/widget-components/basicComponents';
import { nestResponsiveStyles } from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { textSelectors, menuSelectors } from './utils';

export interface MenuItemProps {
    title: string;
    children: React.ReactNode;
    dataAuto?: string;
}

export default function MenuItem({ title, children, dataAuto }: MenuItemProps) {
    const { styles } = useFilterSortContext();
    const { menuStyle, textStyle } = styles;

    return (
        <>
            <Accordion
                title={title}
                styles={{
                    title: textStyle?.title,
                    headerWrapper: textStyle?.title,
                }}
                isAccordionOpen
                data-auto={dataAuto}
                dataGrabs={{ titleDataGrab: textSelectors.title }}
            >
                {children}
            </Accordion>
            <Span
                styles={[
                    dividerDefaultStyles,
                    menuStyle?.divider,
                    nestResponsiveStyles(
                        {
                            [RuntimeMediaQuery.COMMON]: {
                                display: 'none',
                            },
                        },
                        { innerSelector: '&:last-child' }
                    ),
                ]}
                data-grab={menuSelectors.divider}
            />
        </>
    );
}

const dividerDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        backgroundColor: '#e1e1e1',
        marginBlock: '24px',
        display: 'block',
        height: '2px',
        minHeight: '2px',
    },
};
