import { Img } from 'client/widget-components/basicComponents';
import React, { FC } from 'react';
import { context } from '@shared-services/context-service';
import { localizationService } from '@shared-services/localization-service';
import { Div } from 'client/widget-components/basicComponents';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import Text from 'client/widget-components/basicComponents/Text';

interface Props {
    containerStyles?: ResponsiveStylesProp;
}

export const Placeholder: FC<Props> = ({ containerStyles }) => {
    if (!context.isEditor) {
        return null;
    }

    return (
        <Div styles={[containerDefaultStyle, containerStyles]}>
            <Img
                src='https://static-cdn.multiscreensite.com/react-widgets/placeholder.svg'
                alt='placeholder'
            />
            <Div styles={textWrapper}>
                <Img
                    src='https://static-cdn.multiscreensite.com/react-widgets/i_Plus.svg'
                    alt='plus'
                />
                <Text styles={textStyle}>
                    {localizationService.str('placeholder.add-content')}
                </Text>
            </Div>
        </Div>
    );
};
const containerDefaultStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
    },
};

const textWrapper: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        color: '#616C79',
    },
};

const textStyle: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#616C79',
        paddingInlineStart: '4px'
    },
};
