import React from 'react';
import { Div, Label, Svg } from 'client/widget-components/basicComponents';

import { Input } from 'client/widget-components/basicComponents/Input/Input';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import Text, {
    TextDomTag,
} from 'client/widget-components/basicComponents/Text';

type ComponentDataGrabs = {
    labelDataGrab?: string;
    iconWrapperDataGrab?: string;
    iconDataGrab?: string;
};
export interface CheckBoxProps {
    label: string;
    dataGrabs?: ComponentDataGrabs;
    checked: boolean;
    onChange: (isChecked: boolean) => void;
    labelWrapperStyles?: ResponsiveStylesProp;
    labelStyles?: ResponsiveStylesProp;
    inputStyles?: ResponsiveStylesProp;
    inputIconStyles?: ResponsiveStylesProp;
}

function Checkbox({
    label,
    checked,
    onChange,
    labelWrapperStyles,
    labelStyles,
    inputStyles,
    inputIconStyles,
    dataGrabs,
    ...rest
}: CheckBoxProps) {
    return (
        <Label
            styles={[checkBoxWrapperDefaultStyles, labelWrapperStyles]}
            {...rest}
        >
            <Input
                type='checkbox'
                checked={checked}
                onChange={(e) => {
                    e.stopPropagation();
                    onChange(e.target.checked);
                }}
                styles={{
                    [RuntimeMediaQuery.COMMON]: {
                        display: 'none',
                    },
                }}
            />
            <Div
                styles={[inputWrapperDefaultStyles, inputStyles]}
                data-grab={dataGrabs?.iconWrapperDataGrab}
            >
                <Svg
                    width='8'
                    height='7'
                    viewBox='0 0 8 7'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    data-grab={dataGrabs?.iconDataGrab || 'input-icon'}
                    styles={[
                        inputIconStyles,
                        {
                            [RuntimeMediaQuery.COMMON]: {
                                display: checked ? 'unset' : 'none',
                                background: 'none',
                            },
                        },
                    ]}
                >
                    <path
                        d='M0.760254 3.57145L2.6191 5.5L6.47454 1.5'
                        stroke='currentColor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </Svg>
            </Div>
            <Text
                styles={[checkboxLabelDefaultStyles, labelStyles]}
                data-grab={dataGrabs?.labelDataGrab}
                tag={TextDomTag.paragraph}
            >
                {label}
            </Text>
        </Label>
    );
}

const checkBoxWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        color: '#313131',
    },
};
const inputWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14px',
        height: '14px',
        backgroundColor: '#fff',
        border: '1px solid #ced6d9',
    },
};
const checkboxLabelDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        textTransform: 'capitalize',
        flex: 1,
        margin: 0,
        display: 'grid',
        alignItems: 'center',
    },
};

export default Checkbox;
