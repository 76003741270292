import { getWindowInBrowser } from '../../RuntimeWindow';

export const getItemUrl = ({
    collectionPath,
    itemUrl,
}: {
    collectionPath: string;
    itemUrl: string;
}) => {
    const windowInBrowser = getWindowInBrowser();

    if (windowInBrowser?.dmAPI?.getCurrentEnvironment?.() === 'live') {
        return `/${collectionPath}/${itemUrl}`;
    }
    return `/site/${windowInBrowser?.Parameters?.SiteAlias}/${collectionPath}/${itemUrl}${document?.location?.search}`;
};
