import React from 'react';
import { WarningIcon } from 'client/widget-components/components/icons';
import { useFilterSortStyles } from './utils/filterSortContext';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import Button from 'client/widget-components/components/Button';
import { Div } from 'client/widget-components/basicComponents';

export interface TriggerButtonProps {
    onClick: () => void;
    showWarning?: boolean;
    text: string;
    styleWrapperSelector: string;
    selectedCount?: number;
    showButtonHoverEffect?: boolean;
}
export const FSButtonWrapperDataAuto = 'fs-btn-wrapper';
export default function TriggerButton(props: TriggerButtonProps) {
    const { triggerStyle } = useFilterSortStyles();
    const { showWarning, onClick } = props;
    const { iconName, showIcon } = triggerStyle?.iconProps || {};
    const btnText = props.selectedCount
        ? `${props.text} (${props.selectedCount})`
        : props.text;

    return (
        <Div
            styles={buttonWrapperDefaultStyles}
            onClick={onClick}
            data-auto={FSButtonWrapperDataAuto}
        >
            <Button
                styles={{
                    root: [buttonDefaultStyles, triggerStyle?.root],
                    rootHover: triggerStyle?.rootHover,
                    text: triggerStyle?.text,
                    rootHoverText: triggerStyle?.rootHoverText,
                    buttonTypeCSSClass: triggerStyle?.buttonTypeCSSClass,
                    icon: triggerStyle?.icon,
                }}
                buttonContent={{
                    text: btnText,
                    iconClassNames: [triggerStyle?.iconProps?.iconName || ''],
                }}
                dataGrab={props.styleWrapperSelector}
                iconClass={showIcon && iconName}
                showHoverEffect={props.showButtonHoverEffect}
            />
            {showWarning && <WarningIcon size={24} />}
        </Div>
    );
}
const buttonWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
};
const buttonDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: '32px',
        padding: '8px 14px',
        minWidth: 'fit-content',
        textAlign: 'center',
        margin: 0,
    },
};
