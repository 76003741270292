import { logger } from '@shared-services/log-service';
import { upgradeStyles } from 'client/widget-components/responsive-styles/deprecated/styled-components-utils';
import { featureFlags } from '@shared-services/feature-flags';
import { migrateOldBPStyles } from './migrateOldBPStyles';
import {
    AnyStyleStructure,
    MigrateStylesProps,
    OldBPStyles,
    StylesType,
} from './types';
import { getWindowInBrowser } from 'client/RuntimeWindow';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles';

export function normalizeStyles({
    stylesInput,
    migrationStyleKeys,
    monitorKey,
}: MigrateStylesProps) {
    if (!stylesInput) {
        return stylesInput;
    }
    try {
        const { newStyles, inputStylesType } = migrateStyles({
            stylesInput,
            migrationStyleKeys,
        });
        if (monitorKey && inputStylesType !== StylesType.NEW_BP) {
            featureFlags.getBoolean(
                `runtime.ssr.widget.migration.${monitorKey}`,
                false
            ) &&
                logger.info({
                    widget: monitorKey,
                    key: 'breakpoints style migration',
                    type: inputStylesType,
                    stylesInput,
                    newStyles,
                    tags: ['style-migration'],
                    siteAlias: getWindowInBrowser()?.Parameters?.SiteAlias,
                });
        }
        return newStyles;
    } catch (e) {
        logger.error({
            message: `Failed to migrate old styles structure`,
            errorMessage: e?.toString(),
            tags: ['style-migration', 'style-migration-error', monitorKey],
        });
    }
}

function migrateStyles({
    stylesInput = {},
    migrationStyleKeys,
}: MigrateStylesProps) {
    const currentStyleType = detectStyleStructure(stylesInput);
    let newStyles;
    switch (currentStyleType) {
        case StylesType.NO_BP:
            newStyles = migrateOldBPStyles(
                // upgradeStyles: convert no-breakpoints to old-breakpoints styles
                upgradeStyles(stylesInput as OldBPStyles) || {},
                migrationStyleKeys
            );
            break;
        case StylesType.OLD_BP:
            newStyles = migrateOldBPStyles(
                stylesInput || {},
                migrationStyleKeys
            );
            break;
        default:
            newStyles = stylesInput;
            break;
    }
    return { newStyles, inputStylesType: currentStyleType };
}

export function detectStyleStructure(
    styles: AnyStyleStructure
): StylesType | null {
    if (styles && typeof styles === 'object') {
        if ('rules' in styles) {
            return StylesType.OLD_BP;
        }
        const stylesValues = Object.values(styles);
        if (
            (stylesValues.length === 1 && (styles as any).buttonTypeCSSClass) || // button migration edge case
            stylesValues.length === 0 ||
            stylesValues.some(
                (ruleset) =>
                    ruleset[RuntimeMediaQuery.COMMON] ||
                    // checking for innerConfiguration breakpoints
                    Object.values(ruleset || {}).some(
                        (innerRuleset: any) =>
                            innerRuleset?.[RuntimeMediaQuery.COMMON]
                    )
            )
        ) {
            return StylesType.NEW_BP;
        }
        return StylesType.NO_BP;
    }
    return null;
}
