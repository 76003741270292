import React, { FC } from 'react';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { SearchItem } from './SearchItem';
import { Div } from 'client/widget-components/basicComponents';

interface HiddenItemForEditorComputedStylesProps {
    styles?: {
        dropdownItemHover?: ResponsiveStylesProp;
    };
}

export const HiddenItemForEditorComputedStyles: FC<
    HiddenItemForEditorComputedStylesProps
> = ({ styles }) => {
    return (
        <Div styles={hiddenItemStyles}>
            <SearchItem
                item={{
                    data: {
                        identifier: '',
                        name: '',
                        image: '',
                        displayed_price: '',
                    },
                    page_item_url: '',
                }}
                styles={styles}
                grabId='collection-search-widget-dropdown-item'
            />
        </Div>
    );
};

const hiddenItemStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'none',
    },
};
