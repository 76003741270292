import React, { FC, useEffect } from 'react';
import { useIsVisible } from 'client/widget-components/customHooks/useIsVisible';
import { ResponsiveStylesProp } from 'client/widget-components/responsive-styles/responsiveStylesService';
import { getItemUrl } from '../getUrls';
import { Collection } from '../types';
import { SearchItem } from './SearchItem';
import { Div } from 'client/widget-components/basicComponents';

interface SearchGroupProps {
    collection: Collection;
    styles?: {
        dropdownItemHover?: ResponsiveStylesProp;
    };
    fetchMore: () => void;
}

export const SearchGroup: FC<SearchGroupProps> = ({
    collection,
    styles,
    fetchMore,
}) => {
    const { isVisible, elementRef } = useIsVisible();

    useEffect(() => {
        if (isVisible) {
            fetchMore();
        }
    }, [isVisible]);

    if (!collection.items) {
        return null;
    }

    return (
        <Div>
            {collection.items.map((item) => (
                <SearchItem
                    key={item.data.identifier}
                    href={getItemUrl({
                        collectionPath: collection.path,
                        itemUrl: item.page_item_url,
                    })}
                    styles={styles}
                    item={item}
                    grabId='collection-search-visible-dropdown-item'
                />
            ))}

            <div data-auto='end-of-items-container' ref={elementRef} />
        </Div>
    );
};
