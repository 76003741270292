import React, { forwardRef } from 'react';
import { Div } from 'client/widget-components/basicComponents';
import {
    ResponsiveStylesStructured,
    RuntimeMediaQuery,
} from '@duda-co/responsive-styles';
import { RangeValues, TrackProps } from '../types';

interface Props extends TrackProps {
    rangeValues: RangeValues;
}

const RangeSliderTrack = forwardRef<HTMLDivElement, Props>(
    (
        {
            trackStyles,
            trackDataGrab = 'range-slider-track',
            rangeValues,
            ...props
        },
        ref
    ) => {
        const trackCommonStyles = [commonTrackDefaultStyles, trackStyles];

        return (
            <Div
                data-auto='slider-track-wrapper'
                styles={trackWrapperDefaultStyles}
                ref={ref}
            >
                <Div
                    styles={[trackDefaultStyles, trackCommonStyles]}
                    data-grab={trackDataGrab}
                />
                {!props.hideRangeTrack && (
                    <Div
                        styles={[
                            getRangeTrackStyles({
                                ...rangeValues,
                                min: props.min,
                                max: props.max,
                            }),
                            trackCommonStyles,
                        ]}
                    />
                )}
            </Div>
        );
    }
);

function getRangeTrackStyles({
    start,
    end,
    min,
    max,
}: Record<string, number>): ResponsiveStylesStructured {
    const totalDistance = max - min;
    const startDistance = ((start - min) / totalDistance) * 100;
    const endDistance = ((end - start) / totalDistance) * 100;
    return {
        [RuntimeMediaQuery.COMMON]: {
            height: '100%',
            position: 'absolute',
            borderRadius: '24px',
            marginInlineStart: `${startDistance}%`,
            width: `${endDistance}%`,
            top: 0,
        },
    };
}

const trackWrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        backgroundColor: 'transparent',
        position: 'relative',
        width: '100%',
    },
};

const trackDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        opacity: 0.5,
        width: '100%',
    },
};
const commonTrackDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        height: '2px',
        backgroundColor: '#000',
        borderRadius: '6px',
    },
};
export default RangeSliderTrack;
